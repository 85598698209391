import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import './AnnouncementForm.css';

const symbols = [
  // Your symbols array here
];

const strikePrices = Array.from({ length: 3001 }, (_, i) => i);

function AnnouncementForm() {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [platforms, setPlatforms] = useState({ telegram: false, discord: false });
  const [BoughtSold, setBoughtSold] = useState('Bought');
  const [callPut, setCallPut] = useState('Call');
  const [ticker, setTicker] = useState('');
  const [filteredSymbols, setFilteredSymbols] = useState(symbols);
  const [strikePrice, setStrikePrice] = useState('');
  const [filteredStrikePrices, setFilteredStrikePrices] = useState(strikePrices);
  const [date, setDate] = useState('');
  const [price, setPrice] = useState('');
  const [SoldPositionSize, setSoldPositionSize] = useState('1'); // New state for Sold position size
  const [useDropdown, setUseDropdown] = useState(true);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  // Handle message input change
  const handleInputChange = (e) => setMessage(e.target.value);

  useEffect(() => {
    if (!useDropdown && message.trim() !== '') {
      const autoSave = async () => {
        try {
          const logData = {
            message,
            timestamp: new Date().toISOString(),
          };
          await axios.post('https://rollin-trades-announcement-app.onrender.com/logs/normalLogs', { log: logData });
        } catch (error) {
          console.error('Error auto-saving log:', error);
        }
      };
      autoSave();
    }
  }, [message, useDropdown]);
  

  // Handle file selection and preview
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    setFilePreviews(selectedFiles.map(file => URL.createObjectURL(file)));
  };

  // Handle platform selection (Telegram, Discord)
  const handlePlatformChange = (e) => {
    const { name, checked } = e.target;
    setPlatforms(prevPlatforms => ({ ...prevPlatforms, [name]: checked }));
  };

  // Handle emoji selection
  const handleEmojiSelect = (emoji) => setMessage(prevMessage => `${prevMessage}${emoji.native}`);

  // Handle ticker search in dropdown
  const handleTickerSearch = (e) => {
    const value = e.target.value.toUpperCase();
    setTicker(value);
    setFilteredSymbols(symbols.filter(symbol => symbol.includes(value)));
  };

  // Handle strike price search in dropdown
  const handleStrikePriceSearch = (e) => {
    const value = e.target.value;
    setStrikePrice(value);
    setFilteredStrikePrices(strikePrices.filter(price => price.toString().startsWith(value)));
  };

  // Function to post the message to Telegram
  const postToTelegram = async (message, chatId) => {
    const telegramToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    const formData = new FormData();
    formData.append('chat_id', chatId);
    if (files.length > 0) {
      files.forEach((file, index) => formData.append(`photo${index}`, file));
      formData.append('caption', message);
    } else {
      formData.append('text', message);
    }
    const url = files.length > 0
      ? `https://api.telegram.org/bot${telegramToken}/sendMediaGroup`
      : `https://api.telegram.org/bot${telegramToken}/sendMessage`;
    try {
      await axios.post(url, formData);
    } catch (error) {
      console.error('Error posting to Telegram:', error);
    }
  };

  // Function to post the message to Discord
  const postToDiscord = async (message, webhookUrl) => {
    const formData = new FormData();
    formData.append('content', message);
    if (files.length > 0) {
      files.forEach((file, index) => formData.append(`file${index}`, file));
    }
    try {
      await axios.post(webhookUrl, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    } catch (error) {
      console.error('Error posting to Discord:', error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Format message based on dropdown or free-text input
    const formattedMessage = useDropdown
    ? `${BoughtSold} ${
        BoughtSold === 'Sold'
          ? SoldPositionSize === '1'
            ? 'Full Position'
            : `${SoldPositionSize} of`
          : ''
      } $${ticker} ${strikePrice} ${callPut} $${price} ${date}`
    : message;

    // Post to Telegram if selected
    if (platforms.telegram) await postToTelegram(formattedMessage, process.env.REACT_APP_TELEGRAM_CHAT_ID);
    
    // Post to Discord if selected
    if (platforms.discord) {
      const discordWebhookUrl = useDropdown
        ? process.env.REACT_APP_DISCORD_WEBHOOK_URL_TRADE_ALERTS
        : process.env.REACT_APP_DISCORD_WEBHOOK_URL_PRO_ALERTS;
      await postToDiscord(formattedMessage, discordWebhookUrl);
    }

    // Save log to backend
    try {
      const logType = useDropdown ? 'dropdownLogs' : 'normalLogs';

      const logData = useDropdown
        ? {
            action: BoughtSold,
            ticker: ticker,
            strikePrice: strikePrice,
            optionType: callPut,
            price: price,
            expiryDate: date,
            SoldTransactions: BoughtSold === 'Sold' ? [{
              SoldPositionSize: SoldPositionSize,
              SoldPrice: price,
              SoldTimestamp: new Date().toISOString()
            }] : [],
            timestamp: new Date().toISOString(),
          }
        : {
            message: formattedMessage,
            timestamp: new Date().toISOString(),
          };

      await axios.post(`https://rollin-trades-announcement-app.onrender.com/logs/${logType}`, { log: logData });
    } catch (error) {
      console.error('Error saving log:', error);
    }

    // Reset form fields
    setMessage('');
    setFiles([]);
    setFilePreviews([]);
    setBoughtSold('Bought');
    setCallPut('Call');
    setTicker('');
    setStrikePrice('');
    setDate('');
    setPrice('');
    setSoldPositionSize('1');
    setFilteredSymbols(symbols);
    setFilteredStrikePrices(strikePrices);
  };

  // Clear selected images
  const handleClearImages = () => {
    setFiles([]);
    setFilePreviews([]);
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  // Navigate to logs view
  const handleViewLogs = () => navigate('/logs');

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
  };

  return React.createElement('form', { className: 'announcement-form', onSubmit: handleSubmit },
    React.createElement('h1', null, 'Rollin Trades'),
    React.createElement('h2', null, 'Send announcements to Discord and Telegram'),
    React.createElement('label', null, 'Use Dropdown:',
      React.createElement('input', {
        type: 'checkbox',
        checked: useDropdown,
        onChange: (e) => setUseDropdown(e.target.checked)
      })
    ),
    useDropdown ? React.createElement('div', { className: 'input-group' },
      React.createElement('label', null, 'Bought/Sold:',
        React.createElement('select', {
          value: BoughtSold,
          onChange: (e) => setBoughtSold(e.target.value)
        }, [
          React.createElement('option', { key: 'bought', value: 'Bought' }, 'Bought'),
          React.createElement('option', { key: 'sold', value: 'Sold' }, 'Sold')
        ])
      ),
      BoughtSold === 'Sold' && React.createElement('label', null, 'Sold Position Size:',
        React.createElement('select', {
          value: SoldPositionSize,
          onChange: (e) => setSoldPositionSize(e.target.value)
        }, [
          React.createElement('option', { key: 'All', value: 'All' }, 'All'),
          React.createElement('option', { key: '1/2', value: '1/2' }, '1/2'),
          React.createElement('option', { key: '1/3', value: '1/3' }, '1/3'),
          React.createElement('option', { key: '2/3', value: '2/3' }, '2/3'),
          React.createElement('option', { key: '1/4', value: '1/4' }, '1/4'),
          React.createElement('option', { key: '3/4', value: '3/4' }, '3/4')
        ])
      ),
      React.createElement('label', null, 'Call/Put:',
        React.createElement('select', {
          value: callPut,
          onChange: (e) => setCallPut(e.target.value)
        }, [
          React.createElement('option', { key: 'call', value: 'Call' }, 'Call'),
          React.createElement('option', { key: 'put', value: 'Put' }, 'Put')
        ])
      ),
      React.createElement('label', null, 'Ticker:',
        React.createElement('input', {
          type: 'text',
          value: ticker,
          onChange: (e) => setTicker(e.target.value),
          placeholder: 'Enter Ticker'
        }),
        React.createElement('datalist', { id: 'ticker-list' },
          filteredSymbols.map(symbol => React.createElement('option', { key: symbol, value: symbol }, symbol))
        )
      ),
      React.createElement('label', null, 'Strike Price:',
        React.createElement('input', {
          type: 'number',
          value: strikePrice,
          onChange: (e) => setStrikePrice(e.target.value),
          min: '0',
          step: '0.01',
          placeholder: 'Enter Strike Price'
        }),
        React.createElement('datalist', { id: 'strike-price-list' },
          filteredStrikePrices.map(price => React.createElement('option', { key: price, value: price }, price))
        )
      ),
      React.createElement('label', null, 'Expiry Date:',
        React.createElement('input', {
          type: 'date',
          value: date,
          onChange: (e) => setDate(e.target.value)
        })
      ),
      React.createElement('label', null, 'Price:',
        React.createElement('input', {
          type: 'number',
          step: '0.01',
          value: price,
          onChange: (e) => setPrice(e.target.value)
        })
      )
    ) : React.createElement('textarea', {
      value: message,
      onChange: handleInputChange,
      placeholder: 'Enter your message'
    }),
    React.createElement('input', {
      type: 'file',
      multiple: true,
      onChange: handleFileChange,
      ref: fileInputRef,
      accept: 'image/*'
    }),
    filePreviews.length > 0 && React.createElement('div', { className: 'image-preview' },
      filePreviews.map((preview, index) => React.createElement('img', { key: index, src: preview, alt: `Preview ${index}` })),
      React.createElement('button', { type: 'button', onClick: handleClearImages }, 'Clear Images')
    ),
    !useDropdown && React.createElement('div', { className: 'emoji-picker' },
      React.createElement(Picker, { data, onEmojiSelect: handleEmojiSelect })
    ),
    React.createElement('div', { className: 'platform-selection' },
      React.createElement('label', null,
        React.createElement('input', {
          type: 'checkbox',
          name: 'telegram',
          checked: platforms.telegram,
          onChange: handlePlatformChange
        }),
        'Telegram'
      ),
      React.createElement('label', null,
        React.createElement('input', {
          type: 'checkbox',
          name: 'discord',
          checked: platforms.discord,
          onChange: handlePlatformChange
        }),
        'Discord'
      )
    ),
    React.createElement('div', { className: 'dropdown-container' },
      React.createElement('button', { type: 'submit' }, 'Send'),
      React.createElement('button', { type: 'button', onClick: handleViewLogs }, 'View Logs'),
      React.createElement('button', { type: 'button', onClick: handleLogout }, 'Logout'),
      React.createElement('button', { type: 'button', onClick: () => navigate('/templates') }, 'Manage Templates')
    )
  );
}

export default AnnouncementForm;
